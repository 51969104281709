.wrapper.top-v {
  background: #f4f4f4;
}
.top-page-class {
  background: #fff;
  @media #{$media-sp} {
    padding: 60px 0 0 0;
    background: #f4f4f4;
  }
  .indexBanner {
    margin-top: 0 !important;
    height: 650px !important;
    pointer-events: initial !important;
    @media #{$media-sp} {
      width: 100%;
      height: 350px !important;
    }
    .indexSign {
      .MV-text {
        position: relative;
        top: 100px;
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        text-shadow: 0 0 2rem #c29563, 0 0 1rem #b39267;
        @media #{$media-sp} {
          top: 50px;
          font-size: 20px;
        }
      }
    }
    .swiper-container {
      pointer-events: initial !important;
      .swiper-wrapper {
        pointer-events: initial !important;
        .swiper-slide {
          pointer-events: initial !important;
          .hasbg {
            pointer-events: initial !important;
            &::after {
              display: none;
            }
          }
        }
      }
    }
    .swiper-pagination {
      bottom: 15%;
      @media #{$media-sp} {
        bottom: 45px;
      }
      .swiper-pagination-bullet {
        display: inline-block;
        margin: 0 1rem 0 0;
        padding: 0.3rem;
        background: #ffffff;
        opacity: 1;
        &-active {
          background: #319a47;
        }
      }
    }
    .indexSign {
      @media screen and (min-width: 1100px) and (max-width: 2000px) {
        padding: 0;
        width: 1100px;
      }
    }
  }
  .news-sec {
    position: relative;
    top: -2.5rem;
    z-index: 10;
    margin: 0 auto;
    border-radius: 5px;
    width: 1120px;
    background: #fff;
    box-shadow: 0 0.5rem 1rem #eeecea;
    @media #{$media-sp} {
      top: -1rem;
      z-index: 100;
      width: 95%;
    }
    .case-area {
      padding: 1.5rem 1.5rem 1.5rem 3rem;
      border-radius: 5px 0 0 5px;
      width: 85%;
      background: #fff;
      @media #{$media-sp} {
        padding: 0.7rem 1rem 0.7rem 1rem;
        width: 75%;
      }
      .news-txt {
        position: relative;
        padding: 0 0 0 1.7rem;
        width: 15%;
        font-size: 22px;
        color: #533e26;
        @media #{$media-sp} {
          width: 35%;
          font-size: 13px;
          font-weight: bold;
        }
        &::before {
          display: flex;
          position: absolute;
          top: 0.1rem;
          left: 0;
          align-items: center;
          justify-content: center;
          content: url("../img/W.png");
          @media #{$media-sp} {
            top: -4px;
            transform: scale(0.7);
          }
        }
      }
      .date {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        font-size: 19px;
        color: #958a7e;
        @media #{$media-sp} {
          justify-content: left;
          width: 50%;
          font-size: 12px;
          text-align: left;
        }
      }
      .news-title {
        display: flex;
        position: relative;
        align-items: center;
        width: 75%;
        font-size: 18px;
        color: #329a64;
        @media #{$media-sp} {
          padding: 10px 0 0 0;
          width: 100%;
          font-size: 13px;
          font-weight: bold;
        }
        .under {
          position: relative;
          &::after {
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            content: "";
            background: #329a64;
          }
          &:hover {
            &::after {
              display: none;
            }
          }
        }
      }
      .nnbox {
        width: 100%;
      }
      .case-BOX {
        display: block;
        width: 100%;
      }
    }
    .link-jj {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 15%;
      color: #000;
      text-align: center;
      transition-duration: 0.2s;
      @media #{$media-sp} {
        width: 25%;
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 60%;
        content: "";
        background: #a99e92;
        transform: translateY(-50%);
      }
      &:hover {
        border-radius: 0 5px 5px 0;
        color: #fff;
        background: #329a64;
        &::after {
          display: none;
        }
      }
    }
  }
  .bg-g {
    background: url("../img/bg_gray.png");
    background-repeat: no-repeat;
    background-position: bottom 20% right -5rem;
    @media #{$media-sp} {
      position: relative;
      z-index: 10;
      margin: -2rem auto 0;
      padding: 3rem 0 1.5rem;
      width: 90%;
      background: #fff;
    }
  }
  .index2 {
    @media #{$media-sp} {
      overflow: hidden;
    }
  }
  .index2,
  .index3 {
    > div {
      @media #{$media-sp} {
        margin: 0;
      }
    }
    .i2area {
      position: relative;
      padding: 0 0 40px;
      @media #{$media-sp} {
        padding: 0 0 20px;
      }
      .subtitle {
        text-align: center;
        img {
          display: inline;
        }
      }
      .title {
        position: relative;
        padding: 6px 0 0 0;
        font-size: 30px;
        color: #533e26;
        @media #{$media-sp} {
          font-size: 18px;
        }
      }
      &::after {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 3rem;
        height: 2px;
        content: "";
        background: #329a48;
        transform: translateX(-51%);
        @media #{$media-sp} {
          bottom: 8px;
          width: 1.5rem;
        }
      }
    }
    .sec-txt {
      font-size: 18px;
      line-height: 1.8;
      color: #533e26;
      @media #{$media-sp} {
        margin: 0 auto 20px;
        width: 90%;
        font-size: 14px;
        text-align: left;
      }
    }
    article {
      margin-top: 0;
      box-shadow: 10px 10px 10px #e8e7e6;

      @media #{$media-sp} {
        margin: 0 0 23px;
        padding: 0;
        border-radius: 5px;
        box-shadow: 0 10px 15px #e8e7e6;
      }
      div {
        margin: 0;
        padding: 20px 0 30px;
        background: #fff;
        @media #{$media-sp} {
          padding: 20px 0 20px;
          border-radius: 5px;
        }
        .title {
          padding: 0 0 10px 0;
          font-size: 20px;
          font-weight: bold;
          color: #533e26;
          @media #{$media-sp} {
            padding: 0 0 8px 0;
            font-size: 18px;
          }
        }
        .subtitle {
          padding: 0 0 1rem;
          font-size: 15px;
          line-height: 1.8;
          color: #533e26;
          @media #{$media-sp} {
            padding: 0 0 0.8rem;
            font-size: 13px;
          }
        }
        .link-btn {
          display: block;
          position: relative;
          margin: 0 auto;
          padding: 10px 0;
          border: solid 1px #329a48;
          border-radius: 3px;
          width: 80%;
          font-size: 18px;
          color: #329a48;
          text-align: center;
          transition-duration: 0.2s;
          @media #{$media-sp} {
            font-size: 17px;
          }
          &::after {
            position: absolute;
            right: 15px;
            content: url("../img/tria.png");
          }
          &:hover {
            @media #{$media-notsp} {
              color: #fff;
              background: #329a48;
              &::after {
                content: url("../img/tria-w.png");
              }
            }
          }
        }
      }
      &.p-t {
        margin-top: 5rem;
        @media #{$media-sp} {
          margin-top: 0rem;
        }
      }
    }
    .r-bottom {
      padding: 40px 0 80px;
      .right-txt-area {
        padding: 0 2rem 0 0;
        width: 50%;
        p {
          padding: 0 0 18px;
          line-height: 1.8;
          color: #533e26;
        }
        .link {
          margin: 5px 0 0 0;
          a {
            display: block;
            position: relative;
            padding: 10px 0;
            border: solid 1px #329a48;
            border-radius: 3px;
            width: 280px;
            color: #329a48;
            text-align: center;
            transition-duration: 0.2s;
            &::after {
              position: absolute;
              right: 10px;
              content: url("../img/tria.png");
            }
            &:hover {
              @media #{$media-notsp} {
                color: #fff;
                background: #329a48;
                &::after {
                  content: url("../img/tria-w.png");
                }
              }
            }
          }
        }
      }
      .left-img {
        padding: 0 0 0 2rem;
        width: 50%;
      }
    }
    .bg-stclass {
      position: relative;
      @media #{$media-sp} {
        &::before {
          position: absolute;
          top: 100px;
          right: -70px;
          z-index: -10;
          width: 90%;
          height: 45%;
          content: url("../img/bg_gray-sp.png");
        }
        &::after {
          position: absolute;
          bottom: 150px;
          left: -71%;
          z-index: -10;
          width: 90%;
          height: 40%;
          content: url("../img/bg_gray-sp.png");
        }
      }
    }
  }
  .bg-g.n3 {
    margin: 0 0 70px;
    background: none;
    @media #{$media-sp} {
      margin: 0 auto 37px;
      padding: 0 5px;
      background: #fff;
    }
  }
  .index3 {
    @media #{$media-sp} {
      margin: 0;
      .i2area {
        padding: 0 0 30px;
        &::after {
          bottom: 15px;
        }
      }
      .sec-txt {
        margin: 0;
        padding: 15px 0 10px;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
      }
      .r-bottom {
        padding: 0px 0 40px;
        .right-txt-area {
          padding: 0 0 0 0;
          width: 100%;
          p {
            padding: 0 0 10px;
            font-size: 13px;
          }
          .link {
            a {
              margin: 15px auto 0;
              width: 95%;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1700px) and (max-width: 2000px) {
    margin: 0 auto;
    width: 1700px;
  }
  @media #{$media-notsp} and (max-width: 1700px) {
    width: 100%;
  }
}
