// 下層ページの共通要素
.comNews {
  &.single-blog-ver {
    margin: 0 auto;
    max-width: 1700px;
    @media #{$media-sp} {
      margin: 60px 3.125% 0 !important;
    }
  }
  &.single-info {
    position: relative;
    margin: 0 auto;
    border: none;
    max-width: 1700px;
    background: url("../img/archive-info-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media #{$media-sp} {
      position: relative;
      margin: 60px 3.125% 0 !important;
      background: url("../img/archive-info-bg-sp.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .com_wid {
      position: relative;
      .sp-txt {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        @media #{$media-sp} {
        }
      }
    }
  }
}
.news {
  .content {
    .alignright {
      margin: 0 0 0 auto;
    }
  }
  &.single-blog-ver {
    margin: 0 auto 6rem;
    padding: 6rem 0 4rem;
    max-width: 1700px;

    color: #533e26;
    background: #fff;
    @media #{$media-sp} {
      margin: 0 3.125%;
      padding: 2rem 3.125%;
    }
    .tit {
      h5 {
        font-size: 24px;
        text-align: left;
        @media #{$media-sp} {
          font-size: 20px;
        }
      }
      .ddd {
        padding: 0.5rem 0 1rem;
        color: #9f9f9f;
      }
    }
    .con {
      width: 75%;
      @media #{$media-sp} {
        width: 100%;
      }
    }
    .txtarea {
      margin: 0 auto;
      width: 70%;
      @media #{$media-sp} {
        width: 100%;
      }
      p {
        padding: 0 0 1rem;
        @media #{$media-sp} {
          padding: 0 0 0.5rem;
        }
      }
    }
    .img {
      padding: 0 0 1rem;
      max-width: 85%;
      @media #{$media-sp} {
        width: 100%;
      }
    }
    .content {
      p {
        line-height: 2;
      }
    }
    .img-flex {
      margin: 2rem 0 0 0;
      @media #{$media-sp} {
        margin: 1rem 0 0;
      }
      .sns {
        display: inline-block;
        margin: 0 1rem 0 0;
        @media #{$media-sp} {
          margin: 0;
          width: 32%;
        }
      }
    }
    .jbtn {
      padding: 3rem 0 0 0;
      width: 70%;
      @media #{$media-sp} {
        padding: 1rem 0 0 0;
        width: 100%;
      }
      a {
        color: #fff;
      }
      .jump {
        display: flex;
        position: relative;
        justify-content: center;
        margin: 1rem auto;
        padding: 1rem 3rem;

        width: 240px;
        font-weight: bold;
        text-align: center;
        &.maeharav {
          border: solid 1px #ffd817;
          color: #533e26;
          background: #ffd817;
          &::after {
            content: url(../img/right.png);
          }
        }
        &.minamiv {
          border: solid 1px #00b0ff;
          background: #00b0ff;
        }
        &::after {
          position: absolute;
          right: 1rem;
          content: url(../img/right-w.png);
        }
        @media #{$media-sp} {
          margin: 0 auto 0;
          padding: 0.7rem 3rem;
          width: 70%;
        }
      }
    }
    .side {
      margin: -1rem 0 0 0;
      padding: 0rem 0 0 2rem;
      width: 25%;
      @media #{$media-sp} {
        margin: 0;
        padding: 2rem 0 2rem;
        width: 100%;
      }
      .side-title {
        margin: 0 0 0.8rem;
        padding: 0 0 0.5rem;
        border-bottom: solid 1px #533e26;
        font-size: 28px;
        font-weight: bold;
        color: #533e26;
        @media #{$media-sp} {
          font-size: 18px;
        }
        &.maeharaV {
          position: relative;
          &::after {
            position: relative;
            top: 1rem;
            right: -1.5rem;
            content: url(../img/tnk.png);
            @media #{$media-sp} {
              position: absolute;
              top: -1.5rem;
              right: 1rem;
              left: auto;
            }
          }
        }
        &.minamiV {
          position: relative;
          &::after {
            position: relative;
            top: 1rem;
            right: -1.5rem;
            content: url(../img/kjira.png);
            @media #{$media-sp} {
              position: absolute;
              top: -1.5rem;
              right: 1rem;
              left: auto;
            }
          }
        }
      }
      .sidebarclass {
        li {
          a {
            display: block;
            position: relative;
            margin: 0.5rem 0 0;
            padding: 0 0 0 0.5rem;
            color: #6e6e6e;
            &::before {
              content: "-";
            }
          }
        }
      }
    }
  }
  &.single-info {
    margin: 0 auto;
    padding: 0 0 6rem 0;
    color: #533e26;
    @media #{$media-sp} {
      padding: 0 0 2.5rem 0;
    }
    .wi1700 {
      margin: 0 auto;
      padding: 6rem 2rem 0;
      max-width: 1700px;
      background: #fff;
      @media #{$media-sp} {
        padding: 2rem 1rem 2.5rem;
      }
      p {
        color: #533e26;
      }
    }
    .wrapContents {
      margin: 0 auto;
      max-width: 1080px;
      .tit {
        h5 {
          font-size: 24px;
          @media #{$media-sp} {
            font-size: 20px;
          }
        }
        .date {
          padding: 0.5rem 0 1rem;
          color: #9f9f9f;
        }
      }
      .content {
        p {
          padding: 0 0 1.5rem;
          line-height: 2;
          @media #{$media-sp} {
            padding: 0 0 1rem;
          }
        }
        h4 {
          padding: 0 0 0.6rem;
          font-size: 24px;
        }
        h3 {
          padding: 0 0 0.8rem;
          font-size: 28px;
        }
        h2 {
          padding: 0 0 1rem;
          font-size: 32px;
        }
      }
      .other {
        width: 100%;

        .jbtn {
          padding: 2.5rem 0 4rem 0;

          @media #{$media-sp} {
            padding: 2rem 0 0 0;
          }
          a {
            color: #fff;
          }

          .jump {
            display: flex;
            position: relative;
            justify-content: center;
            margin: 1rem auto;
            padding: 1rem 3rem;
            border: solid 1px #329a64;
            width: 240px;
            font-weight: bold;
            text-align: center;
            background: #329a64;
            &::after {
              position: absolute;
              right: 1rem;
              content: url(../img/right-w.png);
            }
            @media #{$media-sp} {
              margin: 0 auto 0;
              width: 70%;
            }
          }
        }
      }
    }
  }
}
