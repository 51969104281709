// 下層ページの共通要素
.comBanner {
  margin-top: 0 !important;
}
.access-page-class {
  padding: 0 0 70px;
  color: #533e26;
  background: #f4f4f4;
  @media #{$media-sp} {
    padding: 0 0 40px;
  }
  img {
    display: inline;
  }
  .MV {
    margin: 0 auto;
    padding: 5.5rem 0;
    max-width: 1700px;
    background: url("../img/access-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$media-sp} {
      margin: 60px auto 0;
      padding: 4rem 0;
      background: url("../img/access-bg-sp.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .width1200 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1200px;
    @media #{$media-sp} {
      padding: 0 25px;
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 10px #666;
    @media #{$media-sp} {
      font-size: 30px;
    }
  }
  .contents {
    margin: 0 auto;
    padding: 0 0 70px;
    max-width: 1700px;
    background: #fff;
    @media #{$media-sp} {
      padding: 0 0 40px;
    }
  }
  .c-title {
    position: relative;
    padding: 0 0 1rem 0.7rem;
    font-size: 30px;
    @media #{$media-sp} {
      padding: 1.5rem 0 1rem 0.7rem;
      font-size: 20px;
    }
    &::before {
      position: relative;
      left: -0.5rem;
      content: url(../img/for.png);
      @media #{$media-sp} {
        top: 0.3rem;
      }
    }
  }
  .lefttxt {
    padding: 0 0 0 5rem;
    width: 50%;
    @media #{$media-sp} {
      padding: 0 0 0 0;
      width: 100%;
    }
  }
  .box {
    margin: 0 auto;
    padding: 4rem 0;
    @media #{$media-sp} {
      padding: 0 0 2rem;
    }
    .addr {
      padding: 0 0 0.5rem;
      line-height: 1.8;
    }
    .tel {
      position: relative;
      font-size: 20px;
      font-weight: bold;
      color: #533e26;
      @media #{$media-sp} {
        display: inline-block;
        padding: 0 0 1rem 0rem;
        font-size: 16px;
        // position: absolute;
        // top: 50%;
        // right: 0;
        // transform: translateY(-10%);
      }
      &::before {
        position: relative;
        top: 0.1rem;
        padding: 0 0.3rem 0 0;
        content: url(../img/T-g.png);
      }
    }
    &.bg-g {
      position: relative;
      z-index: 10;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 93%;
        height: 100%;
        content: "";
        background: #f4f4f4;
        @media #{$media-sp} {
          width: 100%;
        }
      }
    }
  }
}
