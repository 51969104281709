// 下層ページの共通要素
.comBanner {
  margin-top: 0 !important;
}
.about-page-class {
  padding: 0 0 70px;
  color: #533e26;
  background: #f4f4f4;
  @media #{$media-sp} {
    padding: 0 0 40px;
  }
  img {
    display: inline;
  }
  .MV {
    margin: 0 auto;
    padding: 5.5rem 0;
    max-width: 1700px;
    background: url("../img/about-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$media-sp} {
      margin: 60px auto 0;
      padding: 4rem 0;
      background: url("../img/about-bg-sp.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .width1200 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1200px;
    @media #{$media-sp} {
      padding: 0 20px;
    }
  }
  .width800 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 800px;
    @media #{$media-sp} {
      padding: 0 15px;
    }
    .left-txt {
      position: relative;
      width: 40%;
      @media #{$media-sp} {
        width: 30%;
      }
      &::after {
        position: absolute;
        right: 5rem;
        width: 2px;
        height: 22px;
        content: "";
        background: #329a64;
        @media #{$media-sp} {
          right: -0.7rem;
        }
      }
    }
    .right-txt {
      margin: 0 0 1rem 0;
      width: 60%;
      @media #{$media-sp} {
        padding: 0 0 0 1.5rem;
        width: 70%;
      }
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 10px #666;
    @media #{$media-sp} {
      font-size: 30px;
    }
  }
  .contents {
    margin: 0 auto;
    padding: 0 0 0px;
    max-width: 1700px;
    background: #fff;
    @media #{$media-sp} {
      padding: 0 0 20px;
    }
  }
  .c-title {
    position: relative;
    padding: 0 0 3rem 0.7rem;
    font-size: 30px;
    @media #{$media-sp} {
      padding: 1.5rem 0 1.5rem 0;
      font-size: 20px;
    }
    &::before {
      position: relative;
      left: -0.5rem;
      content: url(../img/for.png);
      @media #{$media-sp} {
        top: 0.3rem;
      }
    }
    &.planv {
      padding: 0 0 3rem;
      @media #{$media-sp} {
        padding: 1.5rem 0;
      }
    }
  }

  .box {
    margin: 0 auto;
    padding: 4rem 0;
    @media #{$media-sp} {
      padding: 0 0 0rem;
    }
    .ovhidden {
      margin: 0 auto;
      max-width: 1200px;
    }
    &.bg-g {
      position: relative;
      z-index: 0;
      overflow: hidden;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 90%;
        height: 100%;
        content: "";
        background: #f4f4f4;
        @media #{$media-sp} {
          width: 100%;
        }
      }
    }
    .pwrap {
      padding: 0 0 0 8rem;
      @media #{$media-sp} {
        padding: 0 0 0 2rem;
      }
    }
    .y-area {
      padding: 0 4rem 0 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      color: #329a64;
      text-align: center;
      @media #{$media-sp} {
        padding: 0 0.5rem 0 0;
        font-size: 14px;
      }
    }
    .t-area {
      display: flex;
      position: relative;
      align-items: center;
      padding: 0 0 2.5rem 4rem;
      width: 80%;
      font-size: 18px;
      @media #{$media-sp} {
        padding: 0 0 2.5rem 2rem;
        font-size: 14px;
        line-height: 1.2;
      }
      &::before {
        position: absolute;
        top: 10px;
        left: 5px;
        width: 10px;
        height: 10px;
        content: "";
        background: #329a64;
        @media #{$media-sp} {
          top: 3px;
        }
      }
      &::after {
        position: absolute;
        top: 10px;
        left: 9px;
        width: 2px;
        height: 100%;
        content: "";
        background: #329a64;
      }
    }
    .flex {
      &:last-child {
        .t-area {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .box-member {
    margin: 0 auto;
    max-width: 600px;
    .left,
    .rihgt {
      width: 50%;
      line-height: 2;
    }
    .po-t {
      position: relative;

      padding: 0 0 0 1rem;
      width: 40%;
      @media #{$media-sp} {
        padding: 0 0 0 0.5rem;
      }
      &::before {
        position: absolute;
        top: 6px;
        left: 0;
        width: 2px;
        height: 22px;
        content: "";
        background: #329a64;
        @media #{$media-sp} {
          height: 18px;
        }
      }
    }
    .name {
      margin: 0 0 1rem;
      padding: 0 0 0 1rem;
      line-height: 2;
      @media #{$media-sp} {
        padding: 0 0 0 0rem;
      }
    }
    .rihgt {
      padding: 0 0 0 3rem;
      @media #{$media-sp} {
        padding: 0 0 0 1rem;
      }
      .po-t {
      }
    }
  }
  .second-ttl {
    padding: 3rem 0 3rem 0;
  }
}
