/*
#overview
SVGアイコンフォント

「img/icon」ディレクトリ内のSVGファイルを元に生成されたアイコンフォント。
タスクランナーの処理で自動的にフォントファイル+SCSSの生成と本ガイドへの記載が行われる。
「i.icon-ファイル名」または「.iconfont（タグ内にファイル名と同じ文字列を入れる）」で呼び出すことができる（後者はIE非対応）。
サンプルコードではCSS追記のフック用に「.icon」クラスも付けている。
*/

@font-face {
  font-family: iconfont;
  src: url("font/icon/iconfont.eot?3f076f1640b848bd220455d528cd765c?#iefix") format("embedded-opentype"),
url("font/icon/iconfont.woff2?3f076f1640b848bd220455d528cd765c") format("woff2"),
url("font/icon/iconfont.woff?3f076f1640b848bd220455d528cd765c") format("woff");
  font-display: swap;
}

.iconfont {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: iconfont !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-decoration: none;
  text-transform: none;
}
i[class*="icon-"] {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
	&::before {
    @extend .iconfont;
    display: inline-block;
    vertical-align: bottom;
	}
}
/*
#styleguide
.icon-facebook
```
<i class="icon-facebook"></i>
<i class="icon-facebook" style="margin-left:2rem;font-size:2rem"></i>
<i class="icon-facebook" style="margin-left:2rem;font-size:5rem"></i>
<span class="iconfont" style="display: inline-block;line-height: 1;vertical-align: middle;margin-left:2rem;font-size:10rem">facebook</span>
```
*/
$icon-facebook: "\f101";
.icon-facebook::before {
  content: "\f101";
}
/*
#styleguide
.icon-instagram
```
<i class="icon-instagram"></i>
<i class="icon-instagram" style="margin-left:2rem;font-size:2rem"></i>
<i class="icon-instagram" style="margin-left:2rem;font-size:5rem"></i>
<span class="iconfont" style="display: inline-block;line-height: 1;vertical-align: middle;margin-left:2rem;font-size:10rem">instagram</span>
```
*/
$icon-instagram: "\f102";
.icon-instagram::before {
  content: "\f102";
}
/*
#styleguide
.icon-line
```
<i class="icon-line"></i>
<i class="icon-line" style="margin-left:2rem;font-size:2rem"></i>
<i class="icon-line" style="margin-left:2rem;font-size:5rem"></i>
<span class="iconfont" style="display: inline-block;line-height: 1;vertical-align: middle;margin-left:2rem;font-size:10rem">line</span>
```
*/
$icon-line: "\f103";
.icon-line::before {
  content: "\f103";
}
/*
#styleguide
.icon-twitter
```
<i class="icon-twitter"></i>
<i class="icon-twitter" style="margin-left:2rem;font-size:2rem"></i>
<i class="icon-twitter" style="margin-left:2rem;font-size:5rem"></i>
<span class="iconfont" style="display: inline-block;line-height: 1;vertical-align: middle;margin-left:2rem;font-size:10rem">twitter</span>
```
*/
$icon-twitter: "\f104";
.icon-twitter::before {
  content: "\f104";
}
