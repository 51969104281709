// 下層ページの共通要素
.comBanner {
  margin-top: 0 !important;
}
.contact-page-class {
  padding: 0 0 5rem;
  color: #533e26;
  background: #f4f4f4;
  @media #{$media-sp} {
    padding: 0 0 3rem;
  }
  img {
    display: inline;
  }
  .MV {
    margin: 0 auto;
    padding: 5.5rem 0;
    max-width: 1700px;
    background: url("../img/contact-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media #{$media-sp} {
      margin: 60px auto 0;
      padding: 4rem 0;
      background: url("../img/contact-bg-sp.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 10px #666;
    @media #{$media-sp} {
      font-size: 30px;
    }
  }
  .width1200 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1200px;
    @media #{$media-sp} {
      padding: 0 25px;
    }
  }
  .contents {
    margin: 0 auto;
    padding: 0 0 70px;
    max-width: 1700px;
    background: #fff;
    @media #{$media-sp} {
      padding: 0 0 40px;
    }
    .contactmain {
      margin: 0 auto;
      max-width: 1000px;
      @media #{$media-sp} {
        padding: 0 20px;
      }
      .c-title {
        padding: 4rem 0 2rem;
        font-size: 20px;
        text-align: center;
        @media #{$media-sp} {
          padding: 2rem 0 1rem;
          font-size: 14px;
        }
      }
      .wpcf7c-elm-step2 {
        display: none;
      }
    }
    #mw_wp_form_mw-wp-form-256 {
      dl {
        border: none;
        @media #{$media-sp} {
          padding: 12px 0 10px;
        }
        &:nth-child(8) {
          align-items: normal;
          textarea {
            height: 10rem;
            @media #{$media-sp} {
              height: 8rem;
            }
          }
          dt {
            &::before {
              top: 3px;
              transform: translateY(0);
            }
          }
        }
        dd {
          input,
          textarea {
            display: block;
            padding: 6px 12px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
            height: 34px;
            font-size: 14px;
            line-height: 1.42857143;
            color: #555;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          }
        }
      }
      dt {
        position: relative;
        padding: 0 0 0 2rem;
        @media #{$media-sp} {
          padding: 0 0 0 1rem;
        }
        .gbred {
          position: absolute;
          right: 0px;
          background: #329a64;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 2px;
          height: 19px;
          content: "";
          background: #329a64;
          transform: translateY(-50%);
        }
      }
    }
    .contactbtn {
      @media #{$media-sp} {
        margin-top: 20px;
      }
      .btnbtnsub {
        input {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem 0;
          border-radius: 5px;
          width: 260px;
          color: #fff;
          background: #329a64;
          @media #{$media-sp} {
            padding: 0.8rem 1rem;
            width: 200px;
          }
        }
      }
    }
  }
  .contactinfo {
    @media #{$media-sp} {
      text-align: left;
      p {
        display: inline;
        line-height: 1.8;
      }
    }
  }
}
