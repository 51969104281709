// 下層ページの共通要素
.comBanner {
  margin-top: 0 !important;
}
.contact-thanks-page-class {
  color: #533e26;
  background: #f4f4f4;
  @media #{$media-sp} {
  }
  img {
    display: inline;
  }
  .MV {
    margin: 0 auto;
    padding: 5.5rem 0;
    max-width: 1700px;
    background: url("../img/contact-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media #{$media-sp} {
      margin: 60px auto 0;
      padding: 4rem 0;
      background: url("../img/contact-bg-sp.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 10px #666;
    @media #{$media-sp} {
      font-size: 30px;
    }
  }
  .width1200 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1200px;
    @media #{$media-sp} {
      padding: 0 25px;
    }
  }
  .contents {
    margin: 0 auto;
    padding: 0 0 70px;
    max-width: 1700px;
    background: #fff;
    @media #{$media-sp} {
      padding: 0 0 40px;
    }
    .contactmain {
      margin: 0 auto;
      max-width: 1000px;
      @media #{$media-sp} {
        padding: 0 20px;
      }
      .c-title {
        padding: 4rem 0 2rem;
        font-size: 20px;
        line-height: 1.7;
        text-align: center;
        @media #{$media-sp} {
          padding: 2rem 0 1rem;
          font-size: 14px;
          text-align: left;
        }
        .t {
          padding: 0 0 1.5rem;
        }
      }
      &.confirm {
        .wpcf7c-elm-step2 {
          display: block;
        }
      }
    }
  }
}
