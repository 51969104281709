// 下層ページの共通要素
.comNews {
  @keyframes mv-move {
    0% {
      transform: rotate(0deg);
    }
    49% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(8deg);
    }
    99% {
      transform: rotate(8deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @media #{$media-sp} {
  }
  &.maeharav {
    position: relative;
    border: none;
    background: url("../img/archive-maehara-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &::after {
      display: block;
      position: absolute;
      top: 0;
      left: 15%;
      width: 20rem;
      height: 15rem;
      content: "";
      background: url("../img/tnk-mv.png");
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.5s;
      animation: 2s linear 1s infinite normal mv-move;
      @media screen and (min-width: 1550px) and (max-width: 1700px) {
        top: 0%;
        left: 13%;
      }
      @media screen and (min-width: 1300px) and (max-width: 1550px) {
        top: 0%;
        left: 10%;
      }
      @media screen and (min-width: 1100px) and (max-width: 1300px) {
        top: 10%;
        left: 3%;
        background-size: 35% auto;
      }
      @media #{$media-notsp} and (max-width: 1100px) {
        top: 10%;
        left: 3%;
        background-position: left;
        background-size: 35% auto;
        transform: scale(0.5);
      }
      @media #{$media-sp} {
        top: auto;
        right: 0.5rem;
        bottom: -0.7rem;
        left: auto;
        width: 6rem;
        height: 7rem;
        background-size: 60%;
      }
    }
    @media #{$media-sp} {
      border: none;
      background: url("../img/archive-maehara-bg-sp.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  &.minamiv {
    position: relative;
    border: none;
    background: url("../img/archive-minami-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &::after {
      display: block;
      position: absolute;
      top: 0;
      left: 15%;
      width: 20rem;
      height: 15rem;
      content: "";
      background: url("../img/kjira-mv.png");
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.5s;
      animation: 2s linear 1s infinite normal mv-move;
      @media screen and (min-width: 1550px) and (max-width: 1700px) {
        left: 13%;
      }
      @media screen and (min-width: 1300px) and (max-width: 1550px) {
        left: 10%;
      }
      @media screen and (min-width: 1100px) and (max-width: 1300px) {
        left: 3%;
        background-size: 55% auto;
      }
      @media #{$media-notsp} and (max-width: 1100px) {
        left: 3%;
        background-position: left;
        background-size: 50% auto;
        transform: scale(0.5);
      }
      @media #{$media-sp} {
        top: auto;
        right: 0.5rem;
        bottom: -0.5rem;
        left: auto;
        width: 6rem;
        height: 7rem;
        background-size: 100%;
      }
    }
    @media #{$media-sp} {
      border: none;
      background: url("../img/archive-minami-bg-sp.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  &.info {
    border: none;
    background: url("../img/archive-info-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media #{$media-sp} {
      position: relative;
      background: url("../img/archive-info-bg-sp.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  .sp-txt {
    @media #{$media-notsp} {
      justify-content: center;
      margin: 0 auto;
      padding: 0 40px;
      max-width: 1200px;
      font-size: 50px;
      color: #fff;
      text-shadow: 0 0 10px #666;
    }
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 1rem #333;
  }
  &.w1700 {
    margin: auto;
    max-width: 1700px;
    @media #{$media-sp} {
      margin: 0 3.155%;
    }
  }
}
.bg-gray {
  @media #{$media-sp} {
    padding: 60px 0 2rem 0;
  }
  padding: 0 0 6rem;
  background: #f4f4f4;
}

.newsList {
  margin: 0 auto;
  padding: 4rem 3rem;
  width: 75%;
  @media #{$media-sp} {
    padding: 1.5rem 3.125%;
    width: 100%;
  }
  .img {
    width: 30%;
    @media #{$media-sp} {
      width: 100%;
    }
  }
  .txt {
    padding: 0 0 0 2rem;
    width: 70%;
    @media #{$media-sp} {
      padding: 1rem 0 0 0;
      width: 100%;
    }
  }
  .top-line {
    span {
      color: #9f9f9f;
    }
    h5 {
      padding: 0.5rem 0 0.5rem;
      font-size: 20px;
      @media #{$media-sp} {
        font-size: 18px;
      }
    }
  }
  p {
    color: #333;
    @media #{$media-sp} {
      font-size: 14px;
    }
  }

  &.maeharaVer {
    color: #754a00;
  }
  &.minamiVer {
    color: #00b0ff;
  }
  &.info {
    .date {
      padding: 0.6rem 0 0 0;
      color: #9f9f9f;
      @media #{$media-sp} {
        padding: 0;
      }
    }
    .txt {
      padding: 0;
      width: 85%;
      @media #{$media-sp} {
        width: 100%;
      }
      .top-line {
        color: #329a64;
      }
      p {
        color: #333;
      }
    }
  }
}
.wi1700 {
  margin: 0 auto 0;
  max-width: 1700px;
  background: #fff;
  @media #{$media-sp} {
    margin: 0 3.155%;
  }
  .flex-pager {
    display: flex;
    justify-content: center;
    padding: 0 0 6rem 0;
    .m-pager__item {
      margin: 0 0.5rem;
      padding: 1rem 1.6rem;
      border: #329a64 solid 1px;
      color: #333;
      @media #{$media-sp} {
        padding: 0.4rem 0.8rem;
      }
      &.active {
        color: #fff;
        background: #329a64;
      }
      &.next {
        position: relative;
        &::after {
          content: url("../img/page-n.png");
        }
      }
      &.prev {
        position: relative;
        &::after {
          content: url("../img/page-b.png");
        }
      }
    }
    &.maeharaVV {
      .m-pager__item {
        border: #ffd817 solid 1px;
        &.active {
          color: #333;
          background: #ffd817;
        }
      }
    }
    &.minamiVV {
      .m-pager__item {
        border: #00b0ff solid 1px;
        &.active {
          color: #fff;
          background: #00b0ff;
        }
      }
    }
  }
}
.wrap-list-blog-class {
  margin: 0 auto;
  max-width: 1300px;
  .side {
    padding: 4rem 0 0 2rem;
    width: 25%;
    @media #{$media-sp} {
      padding: 2rem 3.125% 2rem;
      width: 100%;
    }
    .side-title {
      margin: 0 0 0.8rem;
      padding: 0 0 0.5rem;
      border-bottom: solid 1px #533e26;
      font-size: 28px;
      font-weight: bold;
      color: #533e26;
      @media #{$media-sp} {
        font-size: 18px;
      }
      &.maeharaV {
        position: relative;
        &::after {
          position: relative;
          top: 1rem;
          right: -1.5rem;
          content: url(../img/tnk.png);
          @media #{$media-sp} {
            position: absolute;
            top: -1.5rem;
            right: 1rem;
            left: auto;
          }
        }
      }
      &.minamiV {
        position: relative;
        &::after {
          position: relative;
          top: 1rem;
          right: -1.5rem;
          content: url(../img/kjira.png);
          @media #{$media-sp} {
            position: absolute;
            top: -1.5rem;
            right: 1rem;
            left: auto;
          }
        }
      }
    }
    .sidebarclass {
      li {
        a {
          display: block;
          position: relative;
          margin: 0.5rem 0 0;
          padding: 0 0 0 0.5rem;
          color: #6e6e6e;
          &::before {
            content: "-";
          }
        }
      }
    }
  }
  &.info {
    .side {
      display: none;
    }
    .newsList {
      width: 100%;
    }
  }
}
