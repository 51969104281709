.header {
  position: relative;
  padding: 30px 0 10px;
  background: #f4f4f4 !important;
  @media #{$media-sp} {
    position: fixed;
    z-index: 10000;
    height: 60px;
    .headIc {
      .mobile-btn {
        position: relative;
        top: -10px;
        right: 15px;
        .ope {
        }
      }
    }
  }
  .logo-class {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    content: url("../img/logo.png");
    @media #{$media-notsp} and (max-width: 1280px) {
      top: -34px;
      left: -34px;
      transform: scale(0.7);
    }
    @media #{$media-sp} {
      width: 100px;
      content: url("../img/logo-sp.png");
    }
  }
  &.hiode {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.7);
    }
  }
  .headBox {
    .headTop {
      display: block;
      .access,
      .tell {
        display: flex;
        position: relative;
        align-items: center;
        padding: 0 0 0 1rem;
        color: #533e26;

        &::before {
          display: flex;
          position: absolute;
          top: 55%;
          left: 0;
          align-items: center;
          justify-content: center;
          content: url("../img/M.png");
          transform: translateY(-50%);
        }
      }
      .tell {
        padding: 0 0 0 1.5rem;
        &::before {
          content: url("../img/T.png");
        }
      }
      .contact-box {
        position: relative;
        top: 0;
        left: 0;
        padding: 10px 0;
        border-radius: 5px;
        width: 220px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        background: #329a64;
        box-shadow: 3px 4px 4px #aaa;
        transition-duration: 0.2s;
        &:hover {
          top: 2px;
          left: 1px;
          box-shadow: 2px 3px 4px #888;
        }
      }
      .box {
        margin: 0 0 0 auto;
        width: 560px;
      }
      &.com_wid {
        max-width: 100%;
      }
    }
  }
  & > .headBox .headNav {
    justify-content: flex-end;
    border: none;
    .main-navigation {
      display: block;
    }
  }
  .headNav {
    margin-top: 10px !important;
    background: #f4f4f4 !important;
    @media #{$media-sp} {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 0 0 auto !important;
    }
    .main-navigation {
      ul {
        li {
          position: relative;
          margin: 0;
          border-right: solid 1px #a3998d;
          border-radius: 0 !important;
          &:first-child {
            border-left: solid 1px #a3998d;
          }
          a {
            padding: 10px 30px 10px 30px;
            color: #533e26;
          }
          &::before {
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            border-radius: none;
            width: 0;
            height: 2px;
            content: "";
            background: #329a48;
            transition-duration: 0.5s;
            transform: translateX(-50%);
          }
          &:hover {
            &::before {
              width: 80%;
            }
          }
          &.active {
            &::before {
              width: 80%;
            }
          }
          &.selec {
            position: relative;
            border-radius: none;
            color: #533e26;
            background: transparent;
            a {
              border-radius: none;
              color: #533e26;
            }

            &::after {
              display: none;
              border-radius: none;
              background: transparent;
            }
          }
        }
      }
    }
  }
  .scroll-menu {
    display: block;
    position: fixed;
    top: 0;
    z-index: 90;

    padding: 0 40px 0 0;
    width: 100%;
    height: 70px;
    white-space: nowrap;
    pointer-events: none;
    background: #fff;
    box-shadow: 0 3px 5px #eee;
    opacity: 0;
    transition-duration: 0.2s;
    transform: translateY(-35px);
    @media screen and (min-width: 770px) and (max-width: 1200px) {
      right: 0;
      padding: 0 20px 0 0;
    }
    ul {
      position: absolute;
      right: 40px;
      align-items: right;
      justify-content: right;
      margin: 0 0 0 auto;
      padding: 14px 0;
      text-align: right;
      @media screen and (min-width: 770px) and (max-width: 1200px) {
        right: 20px;
      }
      li {
        position: relative;
        margin: 0;
        border-right: solid 1px #a3998d;
        border-radius: 0 !important;
        text-align: right;
        &:first-child {
          border-left: solid 1px #a3998d;
        }
        &:nth-child(5) {
        }
        &:last-child {
          padding: 0 0 0 15px;
          border: none;
        }
        a {
          display: inline-block;
          padding: 10px 30px 10px 30px;
          color: #533e26;
          @media screen and (min-width: 770px) and (max-width: 1200px) {
            padding: 10px 20px 10px 20px;
          }
        }
        &::before {
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          border-radius: none;
          width: 0;
          height: 2px;
          content: "";
          background: #329a48;
          transition-duration: 0.5s;
          transform: translateX(-50%);
        }
        &:hover {
          &::before {
            width: 80%;
          }
        }
        &.active {
          &::before {
            width: 80%;
          }
        }
        &.selec {
          position: relative;
          border-radius: none;
          color: #533e26;
          background: transparent;
          a {
            border-radius: none;
            color: #533e26;
          }
          &::before {
            width: 80%;
          }
          &::after {
            display: none;
            border-radius: none;
            background: transparent;
          }
        }
      }
    }
    .cc-p-menu {
      position: relative;
      top: 0;
      left: 0;
      padding: 10px 0;
      border-radius: 5px;
      width: 220px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      background: #329a64;
      box-shadow: 3px 4px 4px #aaa;
      transition-duration: 0.2s;
      @media screen and (min-width: 770px) and (max-width: 1200px) {
        width: 150px;
      }
      &:hover {
        top: 2px;
        left: 1px;
        color: #fff;
        box-shadow: 2px 3px 4px #888;
      }
    }
  }
  &.active {
    .logo-class {
      top: -32px;
      left: -32px;
      transform: scale(0.72);

      @media #{$media-sp} {
        top: -11px;
        left: -10px;
        transform: scale(0.8);
      }
    }
    .scroll-menu {
      pointer-events: initial;
      opacity: 1;
      transform: translateY(0);
    }
  }

  /*
               ######  ########
              ##       ##     ##
      #######  ######  ########
                    ## ##
               ######  ##
  */
  // ↓sp相当幅
  @media #{$media-sp} {
    .headIc {
      .mobile-btn {
        display: flex;
        top: 0px;
        right: 0;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        span {
          display: block;
          position: absolute;
          top: 9px;
          left: 50%;
          z-index: 5;
          margin: 0 0 0 -15px;
          width: 30px;
          height: 2px;
          background: #000;
          background: #40a16f;
          opacity: 1;
          transition: all 0.4s;
          &:first-child {
          }
          &:last-child {
            position: relative;
          }
        }
        &.active {
          span {
            &:first-child {
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              opacity: 0;
              transform: translateX(10px);
            }
            &:last-child {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
  // ↑SP相当幅
}
@media #{$media-sp} {
  .sp-nav {
    position: fixed;
    top: 60px;
    left: 5%;
    z-index: 10;
    margin: 0 auto;
    padding: 0 0 30px;
    width: 90%;
    pointer-events: none;
    opacity: 0;
    transition-duration: 0.5s;
    transform: translateY(-60px);
    .gg-bg {
      margin: 0 auto;
      padding: 35px 10px;
      width: 100%;
      font-size: 15px;
      color: #fff;

      background: #329a64;
      .menusp-nv {
        display: block;
        position: relative;
        padding: 15px 0;
        color: #fff;
        &::after {
          display: block;
          position: absolute;

          bottom: 0;
          left: 5%;
          width: 90%;
          height: 1px;
          content: "";
          background: #84c2a2;
        }
        &.bn {
          &::after {
            display: none;
          }
        }
      }
      .number {
        display: block;
        position: relative;
        margin: 10px auto 10px;
        padding: 14px 0;
        border-radius: 5px;
        width: 90%;
        text-align: center;
        background: #fff;
        &::before {
          position: absolute;
          left: 30px;
          content: url(../img/T.png);
        }
        img {
          display: inline-block;
          width: 150px;
        }
      }
      .mail {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 14px 0;
        border-radius: 5px;
        width: 90%;
        font-weight: bold;
        color: #533e26;
        text-align: center;
        background: #fff;
        &::after {
          position: absolute;
          top: 50%;
          right: 18px;
          content: url(../img/tria-b.png);
          transform: translateY(-50%);
        }
        &::before {
          position: absolute;
          left: 30px;
          content: url(../img/Ma.png);
        }
        img {
          display: inline;
        }
      }
    }
    &.active {
      position: fixed;
      top: 60px;
      left: 5%;
      z-index: 1000;
      padding: 0 0 30px;
      width: 90%;
      pointer-events: initial;
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .bg-over-lay {
    position: fixed;
    z-index: 08;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    background: #f4f4f4;
    opacity: 0;
    transform: translateY(-60px);
    &.active {
      position: fixed;
      top: 60px;
      z-index: 102;
      padding: 0 0 30px;
      width: 100%;
      pointer-events: initial;
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
