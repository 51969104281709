.footer {
  margin-top: 0;

  background: #329a64;
  @media #{$media-sp} {
    margin-top: 0px;
  }
  .footCont {
    padding: 20px 30px;
    width: 46% !important;
    background: #fff;
    @media #{$media-sp} {
      padding: 25px 25px;
      color: #533e26;
    }
    .footlogo {
      width: 45%;
      @media #{$media-sp} {
        display: block;
        width: 100%;
        text-align: center;
        img {
          display: inline;
        }
      }
    }
    .r-num {
      align-items: center;
      justify-content: center;
      margin: auto 0;
      width: 55%;
      color: #533e26;
      text-align: right;
      @media #{$media-sp} {
        width: 100%;
        text-align: center;
      }
      .foota {
        display: block;
        position: relative;
        @media #{$media-sp} {
          display: inline-block;
        }
        &.t {
          margin: 0 0 10px;
          @media #{$media-sp} {
            margin: 15px 0;
          }
          &::before {
            position: relative;
            top: 6px;
            left: -5px;
            content: url("../img/T.png");
            @media #{$media-sp} {
              position: absolute;
              top: 2px;
              left: -25px;
            }
          }
        }
        &.f {
          position: relative;
          left: -1px;
          &::before {
            position: relative;
            top: 6px;
            left: -5px;
            content: url("../img/F.png");
            @media #{$media-sp} {
              position: absolute;
              top: 4px;
              left: -25px;
            }
          }
        }
      }
      img {
        display: inline-block;
        margin: 0;
      }
    }
  }
  .right-f {
    width: 45%;
    @media #{$media-sp} {
      padding: 30px 0 0 0;
      width: 100%;
    }
    .L,
    .R {
      width: 40%;
      @media #{$media-sp} {
        margin: 0 0 0 20px;
        width: 48%;
      }
    }
    .menu {
      display: block;
      position: relative;
      margin: 0 0 5px;
      padding: 5px 0 5px 15px;
      color: #fff;
      &::before {
        position: absolute;
        left: 0;
        content: url("../img/tria-w.png");
      }
      @media #{$media-notsp} {
        &:hover {
          &::before {
            left: 4px;
          }
        }
      }
      @media #{$media-sp} {
        margin: 0 0 10px;
        padding: 5px 0 5px 30px;
        &::before {
          position: absolute;
          left: 15px;
          content: url("../img/tria-w.png");
        }
      }
    }
    .copyRight {
      .com_wid {
        padding: 27px 0 0 0;
        font-size: 12px;
        color: #fff;
      }
    }
  }
  .sp-footer {
    display: none;
  }
  .footBox {
    @media #{$media-sp} {
      padding: 20px;
      .footCont {
        border-radius: 5px;
        width: 100% !important;
      }
    }
  }
}
