// 下層ページの共通要素
.comBanner,
.comNews {
  margin-top: 0 !important;
}
.greeting-page-class {
  padding: 0 0 5rem;
  color: #533e26;
  background: #f4f4f4;
  @media #{$media-sp} {
    padding: 0 0 3rem;
  }
  img {
    display: inline;
  }
  .MV {
    margin: 0 auto;
    padding: 5.5rem 0;
    max-width: 1700px;
    background: url("../img/greeting-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$media-sp} {
      margin: 60px auto 0;
      padding: 4rem 0;
      background: url("../img/greeting-bg-sp.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .contents {
    margin: 0 auto;
    padding: 0 0 70px;
    max-width: 1700px;
    background: #fff;
    @media #{$media-sp} {
      padding: 0 0 40px;
    }
  }
  .width1200 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1200px;
    @media #{$media-sp} {
      padding: 0 3.125%;
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 10px #666;
    @media #{$media-sp} {
      font-size: 30px;
    }
  }
  .aist {
    position: relative;
    padding: 5rem 0 2rem;
    font-size: 30px;
    @media #{$media-sp} {
      padding: 1.5rem 0 1rem;
      font-size: 20px;
    }
    &::before {
      position: relative;
      top: 0.2rem;
      left: -1rem;
      content: url(../img/for.png);
    }
  }
  .img {
    padding: 0 0 2rem;
    @media #{$media-sp} {
      padding: 0 0 0;
    }
  }
  .txt {
    font-size: 20px;
    @media #{$media-sp} {
      padding: 1rem 0.5rem 0;
      font-size: 16px;
      line-height: 1.6;
      text-align: left;
    }
  }
  .txt2 {
    margin: 0 auto;
    padding: 0 0 80px;
    max-width: 800px;
    line-height: 1.7;
    @media #{$media-sp} {
      padding: 20px 0.5rem 40px;
    }
    .presi {
      padding: 1rem 0 0 0;
      font-size: 18px;
      @media #{$media-sp} {
        padding: 0.6rem 0 0 0;
        font-size: 14px;
      }
    }
  }
  .list {
    position: relative;
    padding: 0 0 0 5rem;
    font-size: 18px;
    line-height: 2.5;
    @media #{$media-sp} {
      padding: 0 0 0 3rem;
      font-size: 14px;
      line-height: 2;
    }
    &::before {
      position: absolute;
      top: 1.2rem;
      left: 4rem;
      width: 8px;
      height: 8px;
      content: "";
      background: #329a64;

      @media #{$media-sp} {
        top: 10px;
        left: 2rem;
      }
    }
  }
  .bg-g {
    position: relative;
    z-index: 10;
    padding: 0 0 6rem 0;
    @media #{$media-sp} {
      padding: 0 0 2rem 0;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -10;
      width: 93%;
      height: 100%;
      content: "";
      background: #f4f4f4;
    }
  }
  .hoiku-box {
    position: relative;
    margin: 0 0 4rem 0;
    min-width: 940px;
    @media screen and (min-width: 770px) and (max-width: 1200px) {
      margin: 0 0 10rem 0;
    }
    @media #{$media-sp} {
      margin: 0 0 0;
      min-width: 100%;
    }
    .img-area {
      position: relative;
      z-index: 101;
      width: 50%;
      @media #{$media-sp} {
        width: 100%;
      }
    }

    .txt-area {
      position: absolute;
      top: 5rem;
      right: 0;
      padding: 2rem 3rem 2rem 10rem;
      width: 60%;
      background: #f4f4f4;
      @media screen and (min-width: 770px) and (max-width: 1200px) {
        padding: 2rem 3rem 2rem 7rem;
      }
      @media #{$media-sp} {
        position: relative;
        top: -2rem;
        right: 0;
        margin: 0 auto;
        padding: 3rem 2rem 1rem 2rem;
        width: 95%;
      }
      .name {
        padding: 1.5rem 0 0 0;
        text-align: right;
        @media #{$media-sp} {
          padding: 0.5rem 0 0 0;
          font-size: 12px;
        }
      }
      &-title {
        padding: 0 0 2rem;
        font-size: 20px;
        @media #{$media-sp} {
          padding: 0 0 1rem;
          font-size: 16px;
        }
      }
    }
    &-title {
      padding: 0 0 0 1rem;
      font-size: 28px;
      color: #329a64;
      @media #{$media-sp} {
        padding: 0 0 0 0.5rem;
        font-size: 18px;
      }
    }
    &.noe2 {
      margin: 0 0 10rem 0;
      @media #{$media-sp} {
        margin: 0 0 0;
      }
      .txt-area {
        position: relative;
        top: 5rem;
        z-index: 100;
        padding: 2rem 10rem 2rem 2rem;
        width: 60%;
        background: #f4f4f4;
        @media screen and (min-width: 770px) and (max-width: 1200px) {
          padding: 2rem 7rem 2rem 2rem;
        }
        @media #{$media-sp} {
          top: -2rem;
          z-index: 10;
          padding: 3rem 2rem 1rem 2rem;
          width: 95%;
        }
      }
      .img-area {
        position: absolute;

        right: 0;
        @media #{$media-sp} {
          position: relative;
          z-index: 100;
        }
        .name {
          padding: 1.5rem 0 0 0;
          text-align: right;
        }
        &-title {
          padding: 0 0 2rem;
          font-size: 20px;
        }
      }
    }
    &.lst {
      margin: 0 0 6rem;
      &:first-child {
        margin-bottom: 9rem;
      }
      &:nth-child(3) {
        margin-bottom: 5rem;
      }
      &:nth-child(5) {
        margin-bottom: 12rem;
      }
      // @media screen and (min-width: 768px) and (max-width: 1200px) {
      //   margin: 0 0 12rem;
      // }
      @media #{$media-sp} {
        margin: 0 0rem;
        &:first-child {
          margin: 0 0rem;
        }
        &:nth-child(3) {
          margin: 0 0rem;
        }
        &:nth-child(5) {
          margin: 0 0rem;
        }
      }
    }
    &.lastclass {
      margin: 0 0 10rem 0;
      @media #{$media-sp} {
        margin: 0 0rem;
      }
    }
  }
  .Donation-box {
    margin: 8rem 0 0;
    padding: 4rem 8rem;
    background: #f4f4f4;
    @media #{$media-sp} {
      margin: 1rem 0 0;
      padding: 2rem 1rem;
    }
    .aist {
      padding: 0;
    }
    .sub-title {
      padding: 60px 0 30px;
      font-size: 20px;
      color: #329a64;
      @media #{$media-sp} {
        padding: 30px 0 10px;
        font-size: 16px;
      }
    }
    .list {
      padding: 0 0 0 2rem;
      @media #{$media-sp} {
        padding: 0 0 0 1rem;
      }
      &::before {
        top: 1.4rem;
        left: 0.7rem;
        @media #{$media-sp} {
          top: 0.7rem;
          left: 0.1rem;
        }
      }
    }
    .no {
      .green {
        color: #329a64;
      }
    }
    .no1txt {
      padding: 0 3rem;
      @media #{$media-sp} {
        padding: 0 0.5rem;
        font-size: 14px;
      }
    }
    .totitle {
      padding: 1rem 3rem 0.5rem;
      font-size: 16px;
      color: #329a64;
      @media #{$media-sp} {
        padding: 1rem 0.5rem 0.5rem;
      }
    }
    .left-txt {
      position: relative;
      width: 20%;

      &::after {
        position: absolute;
        top: 0.2rem;
        right: 0;
        width: 2px;
        height: 18px;
        content: "";
        background: #329a64;
        @media #{$media-sp} {
          right: -0.7rem;
        }
      }
    }
    .right-txt {
      padding: 0 0 0 2rem;
      width: 80%;
      @media #{$media-sp} {
        padding: 0 0 0.7rem 1.5rem;
        line-height: 1.5;
      }
    }
    .no {
      padding: 0rem 0 1rem;
      @media #{$media-sp} {
        padding: 0;
      }
      a {
        color: #533e26;
        text-decoration: underline !important;
      }
      &.nn2 {
        padding: 2rem 0 1rem 0;
        @media #{$media-sp} {
          padding: 1rem 0 1rem 0;
        }
      }
    }
    .emer-txt {
      padding: 0 0.8rem;
      @media #{$media-sp} {
        padding: 0;
        font-size: 12px;
      }
      a {
        color: #533e26;
        text-decoration: underline !important;
      }
    }
  }
}
