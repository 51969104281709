// 下層ページの共通要素
.comBanner {
  margin-top: 0 !important;
}
.rec-page-class {
  padding: 0 0 5rem;
  color: #533e26;
  background: #f4f4f4;
  @media #{$media-sp} {
    padding: 0 0 3rem;
  }
  img {
    display: inline;
  }
  .MV {
    margin: 0 auto;
    padding: 5.5rem 0;
    max-width: 1700px;
    background: url("../img/rec-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$media-sp} {
      margin: 60px auto 0;
      padding: 4rem 0;
      background: url("../img/rec-bg-sp.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .width1200 {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1200px;
    @media #{$media-sp} {
      padding: 0 25px;
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 10px #666;
    @media #{$media-sp} {
      font-size: 30px;
    }
  }
  .contents {
    margin: 0 auto;
    padding: 0 0 70px;
    max-width: 1700px;
    background: #fff;
    @media #{$media-sp} {
      padding: 0 0 40px;
    }
  }
  .link-j {
    margin: 0 auto;
    max-width: 1100px;

    .jcc {
      justify-content: center;
      padding: 4rem 0;
      @media #{$media-sp} {
        padding: 2rem 0 0;
      }
    }
    a {
      display: inline-block;
      padding: 0 2rem;
      border-left: solid #533e26 1px;
      color: #329a64;
      text-align: center;
      &:nth-child(1) {
        @media #{$media-sp} {
          position: relative;
          border: none;
          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 20px;
            content: "";
            background: #333;
            transform: translateY(-50%);
          }
        }
      }
      &:nth-child(3) {
        @media #{$media-sp} {
          position: relative;
          border: none;
          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 28px;
            content: "";
            background: #333;
            transform: translateY(-50%);
          }
        }
      }
      &:last-child {
        border-right: solid #533e26 1px;
        @media #{$media-sp} {
          border-right: none;
        }
      }
      @media #{$media-sp} {
        display: block;
        margin: 0.5rem 0;
        padding: 0rem 1rem;
        border: none;
        width: 50%;
        font-size: 14px;
        text-align: center;
        &:nth-child(1),
        &:nth-child(4) {
          border: none;
        }
      }
    }
  }
  .width500 {
    margin: 0 auto;
    max-width: 500px;
    .c-title {
      position: relative;
      padding: 2.5rem 0 1.5rem;
      font-size: 30px;
      text-align: center;
      @media #{$media-sp} {
        padding: 1.5rem 0 1rem;
        font-size: 20px;
      }
      &::before {
        position: relative;
        left: -0.5rem;
        content: url(../img/for.png);
        @media #{$media-sp} {
          top: 0.3rem;
        }
      }
    }
    .list {
      display: flex;
      position: relative;
      align-items: center;
      margin: 0 0 1.5rem 0;
      padding: 1rem 3rem 1rem 2rem;
      border: solid 1px #329a64;
      border-radius: 3px;
      font-size: 18px;
      color: #533e26;
      box-shadow: 5px 5px 10px #eeecea;
      @media #{$media-sp} {
        margin: 0 auto 1rem;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        width: 80%;
        font-size: 14px;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 2rem;
        content: url(../img/tria.png);
        transform: translateY(-50%);
        @media #{$media-sp} {
          right: 1rem;
        }
      }
      &::before {
        display: inline-block;
        position: relative;
        padding: 0 1rem 0 0;
        content: url(../img/pdf.png);
        @media #{$media-sp} {
          padding: 0 0.5rem 0 0;
        }
      }
    }
  }
  .ztxt {
    font-size: 20px;
    line-height: 1.8;
    @media #{$media-sp} {
      margin: 0 auto;
      width: 90%;
      font-size: 14px;
      text-align: left;
    }
    .ggt {
      display: inline-block;
      position: relative;
      font-weight: bold;
      color: #329a64;
      @media #{$media-sp} {
        display: inline;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: "";
        background: #329a64;
        @media #{$media-sp} {
          display: none;
        }
      }
    }
  }
}
